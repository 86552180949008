function getDuration() {
  const start = window.start || new Date()
  const now = new Date()
  const difference = now.getTime() - start.getTime()

  if (difference === 0) {
    return null
  }

  return difference
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (process.env.NODE_ENV === 'production' || window.dev === true) {
    if (!window.posthog) return

    const { posthog } = window;

    const sendPageView = () => {
      posthog.capture('$pageview', {
        duration: getDuration()
      });
    }

    // Minimum delay for reactHelmet's requestAnimationFrame
    const delay = Math.max(32, 0)
    setTimeout(sendPageView, delay);
  }

  return null
}