import * as Sentry from '@sentry/browser';
import './src/styles/global.css';
import {name, version} from './package.json';

Sentry.init({
  dsn: 'https://a45571142fac49199c592b0bac8a7e6e@sentry.io/1471100',
  release: `${name}@${version}`,
});

export const onServiceWorkerUpdateReady = () => {
  console.log(`This application has been updated. Reload to display the latest version?`);
}

window.addEventListener("load", function(event) {
  document.body.classList.add('animated');
});